import styles from "./ProfileDashboardNav.module.css";
import { Avatar } from "@mui/material";
import useApi from "lib/useApi";
interface ProfileDashboardNavProps {
  // user?: any;
  onClick?: any;
  isOpen: boolean;
  userData?: any;
}

function ProfileDashboardNav({
  // user,
  onClick,
  isOpen,
  userData,
}: ProfileDashboardNavProps) {
  const { data: me } = useApi(`/api/users/my_user`, true, {immutable: true});
  const user = me;
  const firstName = user?.first_name || userData?.user?.first_name;
  const lastName = user?.last_name || userData?.user?.last_name;
  return (
    <a className={styles.container} onClick={onClick}>
      <Avatar
        sx={{ bgcolor: user?.color }}
        alt={`${firstName} ${lastName}`}
        src={user?.avatar_urls[0]}
      >
        {firstName?.charAt(0)}
        {lastName?.charAt(0)}
      </Avatar>
    </a>
  );
}

export default ProfileDashboardNav;
