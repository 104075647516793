import { ThemeOptions } from "@mui/material/styles";
import { border, fontSize, minHeight, padding } from "@mui/system";
import type {} from "@mui/x-data-grid-premium/themeAugmentation";

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#6F23FF",
      // main: "#fff",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    text: {
      primary: "#000",
      secondary: "#000",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
    // .css-1ow4h4h-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input

    MuiAutocomplete: {
      styleOverrides: {
          // MuiAutocomplete-listbox
        listbox: {
          height: 200,
        },
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#adadad42",
              borderRadius: "10px",
              padding: "9px 5px 9px 12px",
            },
            "&:hover fieldset": {
              borderColor: "#5c037c",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#5c037c",
            },
          },
          // .css-4vocsk-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall
          "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            // padding: "6px 5px 9px 12px",
          },
        },
      },
      defaultProps: {
        slotProps: {
          paper: {
            elevation: 10,
          },
        },

      },
    },

    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none !important",
          // .css-1164fi1--root-MuiInput-root::after
          "& .MuiInputBase-root": {
            // borderBottom: "none !important",
            "&:after": {
              borderBottom: "none !important",
            },
          },
          "& .MuiDataGrid-main": {
            borderRadius: "10px",
            border: "1px solid #E5E7EB",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#6B7280",
            fontSize: "11px",
            textTransform: "uppercase",
            border: "none !important",
          },
          "& .MuiDataGrid-withBorderColor": {
            border: "none !important",
          },
          "& .MuiDataGrid-container--top [role=row]": {
            backgroundColor: "#F9FAFB !important",
            borderRadius: "10px 10px 0px 0px !important",
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-cell": {
            // color: "rgba(0,0,0,.4)",
            fontWeight: 300,
            display: "flex",
            alignItems: "center",
            border: "none !important",
          },
          "& .MuiDataGrid-row": {
            // alignItems: "center",
            // justifyContent: "center",
            // border: "1px solid #E5E7EB !important",
            "&:nth-of-type(even)": {
              backgroundColor: "#F9FAFB !important",
            },
          },
          //   "& .MuiDataGrid-row:hover": {
          //     backgroundColor: "#F1EBFC !important",
          //   },
          "& .MuiDataGrid-row:focus": {
            backgroundColor: "#F9FAFB",
          },
          //.css-1hhn4dp-MuiDataGrid-root .MuiDataGrid-row.Mui-selected
          //   "& .MuiDataGrid-row.Mui-selected": {
          //     backgroundColor: "#F1EBFC !important",
          //   },
          "& .MuiDataGrid-row .Mui-selected": {
            backgroundColor: "#F9FAFB !important",
          },
          //   "& .MuiDataGrid-cell.Mui-selected": {
          //     backgroundColor: "#F1EBFC !important",
          //   },
          "& .MuiDataGrid-cell:focus": {
            backgroundColor: "#F9FAFB",
            // border: "none !important",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#6F23FF",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          background: "#fff",
          borderRadius: "15px",
          // boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          marginTop: "4px",
          height: "40px !important",
          minHeight: "40px !important",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        // disableElevation: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "#f5f5f5",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        // margin: "dense",
      },
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#adadad42",
          },
          "&:hover fieldset": {
            borderColor: "#5c037c",
            // backgroundColor: "#f5f5f5",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#5c037c",
            // backgroundColor: "#f5f5f5",
          },
          // borderRadius: "10px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&:hover fieldset": {
            borderColor: "#5c037c",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#5c037c",
          },
          borderRadius: "10px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&:hover": {
            borderColor: "#5c037c",
          },
          "&.Mui-focused": {
            borderColor: "#5c037c",
          },
          borderRadius: "10px",
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
        // margin: "dense",
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: "10px",
            },
            "&:hover fieldset": {
              borderColor: "#5c037c",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#5c037c",
            },
            // borderRadius: "10px",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "20px",
        },
      },
    },
  },
};

export default lightThemeOptions;
