import React from "react";
import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "helpers/helpers";
import AnimatedIconButton from "../AnimatedIconButton";

interface NotificationProps {
  show: boolean;
  setShow: (show: boolean) => void;
  messages: string[];
  type: "success" | "error";
}

export default function Notification({
  show,
  setShow,
  messages,
  type,
}: NotificationProps) {
  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(false);
  };

  return (
    <div
      aria-live="assertive"
      style={{ zIndex: 10001 }}
      className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={show}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-x-full opacity-0"
          enterTo="translate-x-0 opacity-100"
          leave="transform ease-in duration-200 transition"
          leaveFrom="translate-x-0 opacity-100"
          leaveTo="translate-x-full opacity-0"
        >
          <div
            className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 ${
              type === "success" ? "border-green-200" : "border-red-200"
            } border-4`}
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon
                    icon={
                      type === "success" ? "check-circle" : "exclamation-circle"
                    }
                    className={`h-6 w-6 ${
                      type === "success" ? "text-green-400" : "text-red-400"
                    }`}
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  {messages.map((message, index) => (
                    <p
                      key={index}
                      className="text-sm font-medium text-gray-900"
                    >
                      {message}
                    </p>
                  ))}
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <AnimatedIconButton
                    onClick={handleClose}
                    ariaLabel="Close notification"
                    icon={<FontAwesomeIcon icon="times" />}
                    color="#FF244E"
                  />
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
