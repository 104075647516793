import { useState, useRef } from "react";
import {
  KnockProvider,
  KnockFeedProvider,
  NotificationIconButton,
  NotificationFeedPopover,
} from "@knocklabs/react";
import { useLocalStorage } from "contexts/LocalStorageContext";

// Required CSS import, unless you're overriding the styling
import "@knocklabs/react/dist/index.css";

const NotificationFeed = () => {
  const [isVisible, setIsVisible] = useState(false);
  const notifButtonRef = useRef(null);

  const knockPublicApiKey = process.env.NEXT_PUBLIC_KNOCK_PUBLIC_API_KEY;
  const knockFeedChannelId = process.env.NEXT_PUBLIC_KNOCK_FEED_CHANNEL_ID;

  const { userId } = useLocalStorage();

  return (
    <KnockProvider apiKey={knockPublicApiKey} userId={userId}>
      <KnockFeedProvider feedId={knockFeedChannelId}>
        <>
          <NotificationIconButton
            ref={notifButtonRef}
            onClick={(e) => setIsVisible(!isVisible)}
          />
          <NotificationFeedPopover
            buttonRef={notifButtonRef}
            isVisible={isVisible}
            onClose={() => setIsVisible(false)}
          />
        </>
      </KnockFeedProvider>
    </KnockProvider>
  );
};

export default NotificationFeed;