const patcher = async (url: string, token: string | null, data: any) => {
  if (!token) {
    throw new Error("Token is not available");
  }

  const newUrl = process.env.NEXT_PUBLIC_CUSTOMER_API_HOST + url;

  try {
    const res = await fetch(newUrl, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const contentType = res.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const responseData = await res.json();
      
      if (res.ok) {
        return responseData;
      } else {
        throw new Error(JSON.stringify(responseData));
      }
    }

    if (!res.ok) {
      throw new Error(`Error patching data: ${res.status} ${res.statusText}`);
    }

    return { success: true, message: "Data patched successfully" };
  } catch (error) {
    console.error("Error patching data", error);
    throw error;
  }
};

const fetchToken = async () => {
  try {
    const res = await fetch("/api/token", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`Error fetching access token: ${res.statusText}`);
    }

    const data = await res.json();
    return data.accessToken;
  } catch (error) {
    console.error("Error fetching access token", error);
    throw error;
  }
};

const patchAPI = async (url: string, data: any) => {
  const token = await fetchToken();
  return patcher(url, token, data);
};

export default patchAPI;