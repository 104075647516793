import { ThemeOptions } from "@mui/material/styles";

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#fff",
    },
    secondary: {
      // main: "#6F23FF",
      main: "#fff",
    },
    //100F1E
    background: {
      default: "#100F1E",
      paper: "#100F1E",
    },
    text: {
      primary: "#fff",
      secondary: "#fff",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          marginTop: "4px",
          height: "40px !important",
          minHeight: "40px !important",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          background: "#100F1E",
          borderRadius: "10px",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: "transparent",
          color: "#fff",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: "#fff",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          background: "transparent",
          color: "#fff",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        // disableElevation: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#100F1E",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
        // margin: "dense",
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-input": {
            padding: "10px",
            backgroundColor: "#100F1E",
            borderRadius: "10px",
            color: "#fff",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#fff",
            },
            "&:hover fieldset": {
              borderColor: "#5c037c",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#5c037c",
            },
            borderRadius: "10px",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "10px",
        },
      },
    },
  },
};

export default darkThemeOptions;
