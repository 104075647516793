import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import MainDialog from "../MainDialog";
import { useLocalStorage } from "contexts/LocalStorageContext";
import postAPI from "lib/postApi";
import { useState } from "react";
import { mutate } from "swr";

interface AddCampaignDialogProps {
  open?: boolean;
  onClose?: any;
}

export default function AddCampaignDialog({
  open,
  onClose,
}: AddCampaignDialogProps) {
  const { orgId } = useLocalStorage();
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    template_name: "",
    starts_at: "",
    processing_starts_at: "",
    campaign_name: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const createNewCampaign = async () => {
    setLoading(true);
    try {
      const { template_name, starts_at, processing_starts_at, campaign_name } =
        form;
      await postAPI(
        `/api/orgs/${orgId}/campaigns/new_from_template?campaign_template[template_name]=${template_name}&campaign_template[starts_at]=${starts_at}&campaign_template[processing_starts_at]=${processing_starts_at}&campaign_template[campaign_name]=${campaign_name}`,
        {}
      ).then((res) => {
        mutate(`/api/orgs/${orgId}/campaigns/my_campaigns`);
        setForm({
          template_name: "",
          starts_at: "",
          processing_starts_at: "",
          campaign_name: "",
        });
        onClose();
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const templateOptions = [
    "single_target_campaign",
    "multi_target_campaign",
    "ascending_range_campaign",
    "descending_range_campaign",
  ];

  return (
    <MainDialog
      maxWidth="md"
      open={open}
      title={"Add Campaign"}
      message={
        <Stack mt={2} alignItems="center" width="100%" spacing={2}>
          <Select
            size="small"
            name="template_name"
            value={form.template_name}
            onChange={handleSelectChange}
            fullWidth
          >
            {templateOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>

          <TextField
            label="Starts At"
            name="starts_at"
            value={form.starts_at}
            onChange={handleChange}
            type="datetime-local"
            fullWidth
          />
          <TextField
            label="Processing Starts At"
            name="processing_starts_at"
            value={form.processing_starts_at}
            onChange={handleChange}
            type="datetime-local"
            fullWidth
          />
          <TextField
            label="Campaign Name"
            name="campaign_name"
            value={form.campaign_name}
            onChange={handleChange}
            fullWidth
          />
          <Stack direction="row" spacing={2}>
            <Button className="tailwind-button-red" onClick={onClose}>
              Close
            </Button>
            <Button
              disabled={loading}
              className={`tailwind-button${loading ? "" : "-green"}`}
              onClick={createNewCampaign}
            >
              {loading ? (
                <CircularProgress sx={{ color: "black", mr: 2 }} size={20} />
              ) : null}
              Create Campaign
            </Button>
          </Stack>
        </Stack>
      }
    />
  );
}
