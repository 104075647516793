import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SCSVG from "/assets/logo/ScLogoSvg.svg";
import { useAppStateContext } from "./state/state";
import ProfileDashboardNav from "components/profile/profileNav/ProfileDashboardNav";
import { Avatar, SvgIcon } from "@mui/material";
import { FontAwesomeIcon } from "helpers/helpers";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 15,
  color: theme.palette.grey[700],
  backgroundColor: "rgba(0,0,0,.05)",
  border: "2px solid rgba(98,85,164,0)",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,.15)",
    color: "#000",
  },
  "&:focused": {
    backgroundColor: "#fff",
    color: "#000",
    border: "2px solid #6F23FF",
  },
  marginRight: theme.spacing(0),
  marginLeft: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    backgroundColor: "transparent",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

interface DashboardProps {
  handleClickAccount: (param: any) => void;
  handleClickNotification: (param: any) => void;
  openAccount: boolean;
  userData?: any;
  handleChangeSearch: (param: any) => void;
  searchDivRef?: any;
}

export default function PrimarySearchAppBar({
  handleClickAccount,
  openAccount,
  handleClickNotification,
  userData,
  handleChangeSearch,
  searchDivRef,
}: DashboardProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const state = useAppStateContext();
  // const { drawerOpen, setDrawerOpenState, user, logOut } = state; // todo this is old?

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <FontAwesomeIcon icon="envelope" />
          </Badge>
        </IconButton>
        <div>Messages</div>
      </MenuItem>
      <MenuItem>
        <IconButton
          onClick={handleClickNotification}
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <StyledBadge badgeContent={12}>
           <FontAwesomeIcon icon="bell" />
          </StyledBadge>
        </IconButton>
        <div>Notifications</div>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar
            alt={userData?.first_name}
            src={userData?.profile_image_base64}
          />
        </IconButton>
        <div>Profile</div>
      </MenuItem>
    </Menu>
  );

  function SCSVGLOGO() {
    return (
      <SvgIcon
        component={SCSVG}
        sx={{
          width: "200px",
          height: "30px",
          fill: "currentColor",
        }}
        inheritViewBox
      />
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        color="primary"
        elevation={0}
        style={{ borderRadius: 0, backgroundColor: "#fff" }}
      >
        <Toolbar>
          <Box sx={{ width: "100px" }}>
            <SCSVGLOGO />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {/* <IconButton
            onClick={handleClickNotification}
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <StyledBadge badgeContent={17}>
              <NotificationIcon />
            </StyledBadge>
          </IconButton> */}
          <ProfileDashboardNav
            userData={userData}
            isOpen={openAccount}
            onClick={handleClickAccount}
          />
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#6F23FF",
    color: "white",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      //   animation: "ripple 1.2s  ease-in-out",
      border: "1px solid #6F23FF",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
